<template>
  <div
    class="w-full mx-auto text-center flex flex-col items-center py-8 lg:py-12"
  >
    <h1
      class="text-4xl md:text-6xl  font-extrabold leading-none tracking-tight"
    >
      Select your plan.
    </h1>
    <p class="mt-4 lg:mt-8 lg:text-4xl font-medium max-w-md leading-tight">
      For every goal, the right path.
    </p>
    <div class="mt-8 lg:mt-12 flex space-x-4 flex-wrap justify-center -mb-12">
      <div
        v-for="(pricing, index) in pricings"
        :key="pricing.name"
        class="p-2 py-4 lg:px-4 lg:py-6 shadow-evenXl rounded-2xl mx-4 mt-4 lg:mt-8"
      >
        <h2 class="font-bold text-sg-red-500">{{ pricing.name }}</h2>
        <h3 class="font-bold text-6xl">
          {{ pricing.price }}<b class="text-base"> Eur</b>
        </h3>
        <p class="text-sm -mt-2 text-gray-600 font-bold leading-none">
          Per person, per month
        </p>
        <table class="mt-8">
          <tr>
            <td class="text-left w-48">
              Saved sets <br />
              of Sgriffles
            </td>
            <td class="font-bold text-right">{{ pricing.sgriffles }}</td>
          </tr>
          <tr class=" border-t-2 border-gray-400">
            <td class="text-left w-48">Downloads</td>
            <td class="font-bold text-right">{{ pricing.downloads }}</td>
          </tr>
          <tr class=" border-t-2 border-gray-400">
            <td class="text-left w-48">
              Shares on <br />
              Social Media
            </td>
            <td class="font-bold text-right">Unlimited</td>
          </tr>
        </table>

        <sg-button
          class="mt-8"
          @clicked="$root.togglePopUp()"
          :checkBox="true"
          :active="index == activePricing ? true : false"
          >{{ index == activePricing ? "Selected" : "Select" }}</sg-button
        >
      </div>
    </div>
    <sg-mail-header :header="false"
      ><template vslot:text
        >Sign in to the newsletter. We will inform you, when yout plan is ready
        to go!</template
      >
    </sg-mail-header>
  </div>
</template>

<script>
import SgMailHeader from "../components/SgMailHeader.vue";
import pricings from "../content/pricings.js";
import SgButton from "../components/SgButton.vue";

export default {
  name: "SgPricings",
  metaInfo: {
    titleTemplate: "%s | Pricing"
  },
  data() {
    return {
      pricings: pricings.pricings,
      activePricing: 1
    };
  },
  components: { SgMailHeader, SgButton }
};
</script>
