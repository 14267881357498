<template>
  <div
    class="w-full mx-auto text-center flex flex-col items-center py-8 lg:py-12"
  >
    <h1
      class="text-4xl md:text-6xl  font-extrabold leading-none tracking-tight"
    >
      The fine tune.
    </h1>
    <p class="mt-4 lg:mt-8 lg:text-4xl font-medium max-w-md leading-tight">
      A change? Edit your picture right here.
    </p>
    <div class="mt-8 lg:mt-12 px-4 lg:px-0">
      <div
        class="px-4 py-2 bg-white shadow-evenXl flex justify-between w-full max-w-screen-sm mx-auto rounded-2xl"
        @click="$root.togglePopUp()"
      >
        <p
          class="transition scale-110 focus:outline-none cursor-pointer font-bold"
        >
          Aa
        </p>

        <img
          class="transition scale-110 focus:outline-none cursor-pointer h-6"
          src="../assets/icons/pen.svg"
          alt="Pen Icon"
        />

        <img
          class="transition scale-110 focus:outline-none cursor-pointer h-6"
          src="../assets/icons/color.svg"
          alt="Pen Icon"
        />

        <img
          class="transition scale-110 focus:outline-none cursor-pointer h-6"
          src="../assets/icons/layout.svg"
          alt="Pen Icon"
        />
      </div>
      <div
        @click="$root.togglePopUp()"
        class="mt-4 lg:mt-8 w-full  cursor-pointer max-w-screen-sm"
      >
        <img
          class=" shadow-evenXl rounded-2xl"
          :src="
            require('../assets/pics/' +
              $route.params.index +
              '_' +
              $route.params.set +
              '.png')
          "
          alt="Editor Image"
        />
      </div>
    </div>
    <sg-mail-header></sg-mail-header>
  </div>
</template>

<script>
import SgMailHeader from "../components/SgMailHeader.vue";

export default {
  metaInfo: {
    titleTemplate: "%s | Image Editor",
    meta: [{ name: "robots", content: "noindex" }]
  },
  components: { SgMailHeader }
};
</script>
