export default {
  pricings: [
    {
      name: "Starter",
      price: "Free",
      sgriffles: "3",
      downloads: "30 "
    },
    {
      name: "Basic",
      price: "4.9",
      sgriffles: "30",
      downloads: "300"
    },
    {
      name: "Premium",
      price: "9.9",
      sgriffles: "Unlimited",
      downloads: "Unlimited"
    }
  ]
};
